.content {
	background-color: #FFFFFF;
}
.contentLabel {
	height: 35px;
	width: 100%;
	margin-bottom: 10px;
}
.contentDetail {
	margin-bottom: 30px;
}